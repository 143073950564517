var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section customize-section",
    attrs: {
      "id": "hair"
    }
  }, [_c('sub-menu', {
    staticClass: "text-center",
    attrs: {
      "items": _vm.hairSubMenuItems,
      "active-sub-page": _vm.activeSubPage
    },
    on: {
      "changeSubPage": function changeSubPage($event) {
        return _vm.changeSubPage($event);
      }
    }
  }), _vm.activeSubPage === 'color' ? _c('div', {
    attrs: {
      "id": "hair-color"
    }
  }, [_c('customize-options', {
    attrs: {
      "items": _vm.freeHairColors,
      "current-value": _vm.user.preferences.hair.color
    }
  }), _vm._l(_vm.seasonalHairColors, function (set) {
    return _vm.editing && set.key !== 'undefined' ? _c('div', {
      key: set.key
    }, [_c('customize-options', {
      attrs: {
        "items": set.options,
        "current-value": _vm.user.preferences.hair.color,
        "full-set": !_vm.hideSet(set.key) && !_vm.userOwnsSet('hair', set.keys, 'color')
      },
      on: {
        "unlock": function unlock($event) {
          _vm.unlock("hair.color.".concat(set.keys.join(',hair.color.')));
        }
      }
    })], 1) : _vm._e();
  })], 2) : _vm._e(), _vm.activeSubPage === 'style' ? _c('div', {
    attrs: {
      "id": "style"
    }
  }, _vm._l(_vm.styleSets, function (set) {
    return _c('div', [_c('customize-options', {
      attrs: {
        "items": set.options,
        "full-set": set.fullSet
      },
      on: {
        "unlock": function unlock($event) {
          return set.unlock();
        }
      }
    })], 1);
  }), 0) : _vm._e(), _vm.activeSubPage === 'bangs' ? _c('div', {
    attrs: {
      "id": "bangs"
    }
  }, [_c('customize-options', {
    attrs: {
      "items": _vm.hairBangs,
      "current-value": _vm.user.preferences.hair.bangs
    }
  })], 1) : _vm._e(), _vm.activeSubPage === 'facialhair' ? _c('div', {
    attrs: {
      "id": "facialhair"
    }
  }, [_vm.editing ? _c('customize-options', {
    attrs: {
      "items": _vm.mustacheList
    }
  }) : _vm._e(), _vm.editing ? _c('customize-options', {
    attrs: {
      "items": _vm.beardList,
      "full-set": _vm.isPurchaseAllNeeded('hair', ['baseHair5', 'baseHair6'], ['mustache', 'beard'])
    },
    on: {
      "unlock": function unlock($event) {
        _vm.unlock("hair.mustache.".concat(_vm.baseHair5Keys.join(',hair.mustache.'), ",hair.beard.").concat(_vm.baseHair6Keys.join(',hair.beard.')));
      }
    }
  }) : _vm._e()], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }