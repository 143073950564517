var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section customize-section",
    attrs: {
      "id": "skin"
    }
  }, [_c('sub-menu', {
    staticClass: "text-center",
    attrs: {
      "items": _vm.skinSubMenuItems,
      "active-sub-page": _vm.activeSubPage
    },
    on: {
      "changeSubPage": function changeSubPage($event) {
        return _vm.changeSubPage($event);
      }
    }
  }), _c('customize-options', {
    attrs: {
      "items": _vm.freeSkins,
      "current-value": _vm.user.preferences.skin
    }
  }), _vm._l(_vm.seasonalSkins, function (set) {
    return _vm.editing && set.key !== 'undefined' ? _c('div', {
      key: set.key
    }, [_c('customize-options', {
      attrs: {
        "items": set.options,
        "current-value": _vm.user.preferences.skin,
        "full-set": !_vm.hideSet(set.key) && !_vm.userOwnsSet('skin', set.keys)
      },
      on: {
        "unlock": function unlock($event) {
          _vm.unlock("skin.".concat(set.keys.join(',skin.')));
        }
      }
    })], 1) : _vm._e();
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }