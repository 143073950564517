var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section container customize-section",
    attrs: {
      "id": "extra"
    }
  }, [_c('sub-menu', {
    staticClass: "text-center",
    attrs: {
      "items": _vm.extraSubMenuItems,
      "active-sub-page": _vm.activeSubPage
    },
    on: {
      "changeSubPage": function changeSubPage($event) {
        return _vm.changeSubPage($event);
      }
    }
  }), _vm.activeSubPage === 'glasses' ? _c('div', {
    attrs: {
      "id": "hair-color"
    }
  }, [_c('customize-options', {
    attrs: {
      "items": _vm.eyewear
    }
  })], 1) : _vm._e(), _vm.activeSubPage === 'ears' ? _c('div', {
    attrs: {
      "id": "animal-ears"
    }
  }, [_c('customize-options', {
    attrs: {
      "items": _vm.animalItems('headAccessory'),
      "full-set": !_vm.animalItemsOwned('headAccessory')
    },
    on: {
      "unlock": function unlock($event) {
        _vm.unlock(_vm.animalItemsUnlockString('headAccessory'));
      }
    }
  })], 1) : _vm._e(), _vm.activeSubPage === 'tails' ? _c('div', {
    attrs: {
      "id": "animal-tails"
    }
  }, [_c('customize-options', {
    attrs: {
      "items": _vm.animalItems('back'),
      "full-set": !_vm.animalItemsOwned('back')
    },
    on: {
      "unlock": function unlock($event) {
        _vm.unlock(_vm.animalItemsUnlockString('back'));
      }
    }
  })], 1) : _vm._e(), _vm.activeSubPage === 'headband' ? _c('div', {
    attrs: {
      "id": "headband"
    }
  }, [_c('customize-options', {
    attrs: {
      "items": _vm.headbands
    }
  })], 1) : _vm._e(), _vm.activeSubPage === 'wheelchair' ? _c('div', {
    attrs: {
      "id": "wheelchairs"
    }
  }, [_c('customize-options', {
    attrs: {
      "items": _vm.chairs
    }
  })], 1) : _vm._e(), _vm.activeSubPage === 'flower' ? _c('div', {
    attrs: {
      "id": "flowers"
    }
  }, [_c('customize-options', {
    attrs: {
      "items": _vm.flowers
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }