var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('menu-dropdown', {
    staticClass: "item-user",
    attrs: {
      "right": true
    }
  }, [_c('div', {
    attrs: {
      "slot": "dropdown-toggle"
    },
    slot: "dropdown-toggle"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      value: _vm.$t('user'),
      expression: "$t('user')",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    attrs: {
      "aria-label": _vm.$t('user')
    }
  }, [_vm.user.inbox.newMessages > 0 ? _c('message-count', {
    attrs: {
      "count": _vm.user.inbox.newMessages,
      "top": true
    }
  }) : _vm._e(), _c('div', {
    staticClass: "top-menu-icon svg-icon user",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.user)
    }
  })], 1)]), _c('div', {
    staticClass: "user-dropdown",
    attrs: {
      "slot": "dropdown-content"
    },
    slot: "dropdown-content"
  }, [_c('a', {
    staticClass: "topbar-dropdown-item nav-link dropdown-item dropdown-separated d-flex justify-content-between align-items-center",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.showPrivateMessages();
      }
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.$t('messages')))]), _vm.user.inbox.newMessages > 0 ? _c('message-count', {
    attrs: {
      "count": _vm.user.inbox.newMessages
    }
  }) : _vm._e()], 1), _c('a', {
    staticClass: "topbar-dropdown-item dropdown-item",
    on: {
      "click": function click($event) {
        return _vm.showAvatar('body', 'size');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('editAvatar')))]), _c('a', {
    staticClass: "topbar-dropdown-item dropdown-item dropdown-separated",
    on: {
      "click": function click($event) {
        return _vm.showAvatar('backgrounds', '2024');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('backgrounds')))]), _c('a', {
    staticClass: "topbar-dropdown-item dropdown-item",
    on: {
      "click": function click($event) {
        return _vm.showProfile('profile');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('profile')))]), _c('a', {
    staticClass: "topbar-dropdown-item dropdown-item",
    on: {
      "click": function click($event) {
        return _vm.showProfile('stats');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('stats')))]), _c('a', {
    staticClass: "topbar-dropdown-item dropdown-item dropdown-separated",
    on: {
      "click": function click($event) {
        return _vm.showProfile('achievements');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('achievements')))]), _c('router-link', {
    staticClass: "topbar-dropdown-item dropdown-item",
    attrs: {
      "to": {
        name: 'general'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('settings')) + " ")]), _c('router-link', {
    staticClass: "topbar-dropdown-item dropdown-item dropdown-separated",
    attrs: {
      "to": {
        name: 'subscription'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('subscription')) + " ")]), _c('a', {
    staticClass: "topbar-dropdown-item nav-link dropdown-item dropdown-separated",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.logout();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('logout')))]), !_vm.user.purchased.plan.customerId ? _c('li', {
    staticClass: "topbar-dropdown-item dropdown-item dropdown-separated d-flex flex-column justify-content-center align-items-center dropdown-inactive subs-info"
  }, [_vm._m(0), _c('img', {
    staticClass: "swords mb-3",
    attrs: {
      "srcset": require("@/assets/images/swords.png") + ", " + require("@/assets/images/swords@2x.png") + " 2x, " + require("@/assets/images/swords@3x.png") + " 3x",
      "src": require("@/assets/images/swords.png")
    }
  }), _vm._m(1), _vm._m(2)]) : _vm._e()], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "purple d-block font-weight-bold mb-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('lookingForMoreItems')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "subs-benefits mb-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('dropCapSubs')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn btn-primary mb-4",
    on: {
      "click": function click($event) {
        return _vm.toLearnMore();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('learnMore')) + " ")]);

}]

export { render, staticRenderFns }