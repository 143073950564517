var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "customize-options",
    class: {
      'background-set': _vm.fullSet
    }
  }, [_vm._l(_vm.items, function (option) {
    return _c('div', {
      key: option.key,
      staticClass: "outer-option-background",
      class: {
        locked: option.gemLocked || option.goldLocked,
        premium: Boolean(option.gem),
        active: option.active || _vm.currentValue === option.key,
        none: option.none,
        hide: option.hide
      },
      on: {
        "click": function click($event) {
          return option.click(option);
        }
      }
    }, [_c('div', {
      staticClass: "option"
    }, [_c('div', {
      staticClass: "sprite customize-option",
      class: option.class
    }, [option.none ? _c('div', {
      staticClass: "redline-outer"
    }, [_c('div', {
      staticClass: "redline"
    })]) : _vm._e()])]), option.gemLocked ? _c('div', {
      staticClass: "gem-lock"
    }, [_c('div', {
      staticClass: "svg-icon gem",
      domProps: {
        "innerHTML": _vm._s(_vm.icons.gem)
      }
    }), _c('span', [_vm._v(_vm._s(option.gem))])]) : _vm._e(), option.goldLocked ? _c('div', {
      staticClass: "gold-lock"
    }, [_c('div', {
      staticClass: "svg-icon gold",
      domProps: {
        "innerHTML": _vm._s(_vm.icons.gold)
      }
    }), _c('span', [_vm._v(_vm._s(option.gold))])]) : _vm._e()]);
  }), _vm.fullSet ? _c('div', {
    staticClass: "purchase-set",
    on: {
      "click": function click($event) {
        return _vm.unlock();
      }
    }
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t('purchaseAll')))]), _c('div', {
    staticClass: "svg-icon gem",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gem)
    }
  }), _c('span', {
    staticClass: "price"
  }, [_vm._v("5")])]) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }