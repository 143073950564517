var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('select-list', {
    staticClass: "array-select",
    class: {
      disabled: _vm.disabled
    },
    attrs: {
      "items": _vm.items,
      "value": _vm.selected,
      "disabled": _vm.disabled,
      "right": _vm.right,
      "hide-icon": false,
      "inline-dropdown": _vm.inlineDropdown,
      "placeholder": _vm.placeholder
    },
    on: {
      "select": function select($event) {
        return _vm.selectItem($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "label"
        }, [_vm._v(_vm._s(_vm.$t(item)))])];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }